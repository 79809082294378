import './index.scss'

import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

/**
 *  banner
 **/
!(function () {
  let swiper = new Swiper('.banner-swiper', {
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    speed:1500,
    simulateTouch: false,
    navigation: {
      nextEl: '.banner-btn.next',
      prevEl: '.banner-btn.prev'
    }
  })
})()